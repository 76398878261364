// Step0.js

import React, { useState, useRef } from 'react';
import CourtSelector from './CourtSelector';
import Icon from '@mdi/react';
import { mdiTrayArrowUp, mdiTrashCanOutline, mdiPlus } from '@mdi/js';
import '../global.css';


function Step0({onSubmit, advSt, onChange, fileName}) {
  const [hiddenValue, setHiddenValue] = useState('');

  /* minimum stuff to check inputs: plaintiff, defendant,  */
  // const plaintiffRef = useRef();
  const [plaintiffGroups, setPlaintiffGroups] = useState([ { plaintiff: '', plaintiffAddress: '' } ]);
  const [defendantGroups, setDefendantGroups] = useState([
    { defendant: '', defendantAddress: '' }
  ]);
  // const defendantRef = useRef();
  const faRef = useRef();
  const fileRef = useRef();

  const updateHiddenValue = (newValue) => {
    setHiddenValue(newValue);
  };

  const validateInputs = async (e) => {
    e.preventDefault(); 

    const firstPlaintiff = plaintiffGroups[0];
    if (!firstPlaintiff.plaintiff.trim()) {
      alert("Plaintiff is required");
      return;
    }
    
    const firstDefendant = defendantGroups[0];
    if (!firstDefendant.defendant.trim()) {
        alert("Defendant is required");
        return; 
    }

    // Check if faRef or fileRef are empty
    const faValid = faRef && faRef.current && faRef.current.value.trim();
    const fileValid = fileRef && fileRef.current && fileRef.current.value
    if (!faValid && !fileValid) {
      console.log("faValid: ", faValid);      
      alert("Factual allegations in the text box or file are required");
      return; 
    }

    // If all validations pass, proceed with form submission
    onSubmit(e);
  }

  // Handle changes for the plaintiff groups
  const handlePlaintiffChange = (index, e) => {
    const { name, value } = e.target;
    // Extract the field name (e.g., "plaintiff" or "plaintiffAddress") from the unique name
    const fieldName = name.split('-')[0];
    const newGroups = [...plaintiffGroups];
    newGroups[index][fieldName] = value;
    setPlaintiffGroups(newGroups);
  };

  // Handle changes for defendant groups
  const handleDefendantChange = (index, e) => {
    const { name, value } = e.target;
    const fieldName = name.split('-')[0]; // extract "defendant" or "defendantAddress"
    const newGroups = [...defendantGroups];
    newGroups[index][fieldName] = value;
    setDefendantGroups(newGroups);
  };
  
  // Add another plaintiff group
  const handleAddPlaintiff = () => {
    setPlaintiffGroups([...plaintiffGroups, { plaintiff: '', plaintiffAddress: '' }]);
  };

  // Remove a plaintiff group at a specific index.
  const handleRemovePlaintiff = (index) => {
    // Optionally, ensure you always have at least one group.
    if (plaintiffGroups.length > 1) {
      const newGroups = plaintiffGroups.filter((_, i) => i !== index);
      setPlaintiffGroups(newGroups);
    }
  };

  // Add new defendant group
  const handleAddDefendant = () => {
    setDefendantGroups([...defendantGroups, { defendant: '', defendantAddress: '' }]);
  };

  // Remove a defendant group
  const handleRemoveDefendant = (index) => {
    if (defendantGroups.length > 1) {
      setDefendantGroups(defendantGroups.filter((_, i) => i !== index));
    }
  };

  return (
    <div>
      <div className='block' style={{ marginLeft: '16px', marginBottom: '16px' }}><h3 className="title is-4">Factual Inputs</h3></div>
      <form onSubmit={validateInputs} style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        {/* Plaintiff Groups */}
        <div className='input-group'>
          {plaintiffGroups.map((group, index) => (
            <div key={index} className='input-group-sub'>
              {/* Header */}
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '32px' }}>
                <h3 className='title is-7' style={{ margin: '0px 4px'}}>Plaintiff {index + 1}</h3>
                {/* Show remove button if there's more than one plaintiff group */}
                {plaintiffGroups.length > 1 && (
                  <button type="button" className='button icon' onClick={() => handleRemovePlaintiff(index)}>
                    <span className='icon-container' style={{ borderRadius: '6px', height: 'auto' }}>
                      <Icon path={mdiTrashCanOutline} size="24px" style={{ color: 'var(--error)' }}/>
                    </span>
                  </button>
                )}
              </div>
              {/* Name */}
              <div>
                <label className='label' htmlFor={`plaintiff-${index}`}>Name</label>
                <input
                  className="input"
                  type="text"
                  id={`plaintiff-${index}`}
                  name={`plaintiff-${index}`}
                  value={group.plaintiff}
                  onChange={(e) => handlePlaintiffChange(index, e)}
                />
              </div>
              {/* Residence or Incorporation/HQ */}
              <div>
                <label className='label' htmlFor={`plaintiffAddress-${index}`}>
                Residence or Incorporation/HQ
                </label>
                <input
                  className="input"
                  type="text"
                  id={`plaintiffAddress-${index}`}
                  name={`plaintiffAddress-${index}`}
                  value={group.plaintiffAddress}
                  onChange={(e) => handlePlaintiffChange(index, e)}
                />
              </div>
            </div>
          ))}
          {/* Add button */}
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <button type="button" className='button icon' onClick={handleAddPlaintiff}>
              <span className='icon-container' style={{ border: '2px solid var(--color-primary)',borderRadius: '6px', height: 'auto' }}>
                <Icon path={mdiPlus} size="20px" style={{ color: 'var(--color-primary)' }}/>
              </span>
            </button>
          </div>
        </div>

        {/* <div>
          <label className='label' htmlFor="defendant">Defendant:</label>
          <input className="input" type="text" id="defendant" name="defendant" ref={defendantRef} />
        </div> */}

        {/* Defendant Groups */}
        <div className='input-group'>
          {defendantGroups.map((group, index) => (
            <div key={`defendant-${index}`} className='input-group-sub'>
              {/* Header */}
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '32px' }}>
                <h3 className='title is-7' style={{ margin: '0px 4px'}}>Defendant {index + 1}</h3>
                {defendantGroups.length > 1 && (
                  <button type="button" className='button icon' onClick={() => handleRemoveDefendant(index)}>
                    <span className='icon-container' style={{ borderRadius: '6px', height: 'auto' }}>
                      <Icon path={mdiTrashCanOutline} size="24px" style={{ color: 'var(--error)' }}/>
                    </span>  
                  </button>
                )}
              </div>
              {/* Name */}
              <div>
                <label className='label' htmlFor={`defendant-${index}`}>Name</label>
                <input
                  className="input"
                  type="text"
                  id={`defendant-${index}`}
                  name={`defendant-${index}`}  // Unique name
                  value={group.defendant}
                  onChange={(e) => handleDefendantChange(index, e)}
                />
              </div>
              {/* Residence or Incorporation/HQ */}
              <div>
                <label className='label' htmlFor={`defendantAddress-${index}`}>
                  Residence or Incorporation/HQ
                </label>
                <input
                  className="input"
                  type="text"
                  id={`defendantAddress-${index}`}
                  name={`defendantAddress-${index}`}  // Unique name
                  value={group.defendantAddress}
                  onChange={(e) => handleDefendantChange(index, e)}
                />
              </div>
            </div>
          ))}
          {/* Add button */}
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <button type="button" className='button icon' onClick={handleAddDefendant}>
              <span className='icon-container' style={{ border: '2px solid var(--color-primary)',borderRadius: '6px', height: 'auto' }}>
                <Icon path={mdiPlus} size="20px" style={{ color: 'var(--color-primary)' }}/>
              </span>
            </button>
          </div>
        </div>
        
        {/* Others Group */}
        <div className='input-group'>
          <div>
            <CourtSelector venuSetter={updateHiddenValue} />
            <input type="hidden" name="venue" value={hiddenValue} />
          </div>
          <div>
            <label className='label' htmlFor="factualAllegations">Factual Allegations:</label>
            <textarea
              className="textarea"
              id="factualAllegations"
              name="factualAllegations"
              rows="8"
              cols="50"
              ref={faRef}
            ></textarea>
          </div>
          <div className='upload-container' style={{ gap: '6px' }}>
            <div>
              <p>If your factual allegations are in a file you can attach it here.</p>
            </div>
            <div className="file block has-name">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  name="fileUpload"
                  id="fileUpload"
                  onChange={onChange}
                  ref={fileRef}
                />
                <span className="file-cta">
                  <span className='icon' style={{ marginRight: '0.25em' }}>
                    <Icon path={mdiTrayArrowUp} size={0.9} />
                  </span>
                  <span className="file-label">
                    {fileName ? fileName.name : "Select a file to upload"}
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
        
        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Draft complaint</button>
        </div>
      </form>

    </div>
  );
}

export default Step0;